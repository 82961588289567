import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { getImage } from "gatsby-plugin-image"
import styled from 'styled-components'

import PageHeaderImageBgNew from '../headers/PageHeaderImageBgNew'
import YoutubePlayerLite from '../common/YoutubePlayerLite'
import PageVideosNav from '../navigation/PageVideosNav'

const StyledDiv = styled.div`
  .text-shadow {
    text-shadow: 0 3px 3px rgba(0,0,0,.6);
  }
`

const Container = 'block'
const vidTitle = 'text-gray-800 text-xl font-semibold mt-1'

const CATVideo = ({ videoTitle, videoID }) => {
  return (
    <div className={Container}>
      <YoutubePlayerLite youtubeID={videoID} />
      <p className={vidTitle}>{videoTitle}</p>
    </div>
  )
}

CATVideo.propTypes = {
  videoTitle: PropTypes.string,
  videoID: PropTypes.string,
}

const VideosSectionNew = () => {
  const data = useStaticQuery(graphql`
    query VideosSectionNewQuery {
      prismicVideosPage {
        data {
          page_title
          list_of_videos {
            video_id
            video_title
          }
        }
      }
      imageBg: file(relativePath: { eq: "header/video-page-bg.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1024
          )
        }
      }
    }
  `)

  const doc = data.prismicVideosPage.data
  const videos = doc.list_of_videos
  const imageBg = getImage(data.imageBg)

  return (
    <>
      <PageHeaderImageBgNew imageData={imageBg} opacity="0.5">
        <StyledDiv className='w-11/12 xl:max-w-screen-xl flex flex-col items-center'>
          <h1 className='font-display font-extrabold text-4xl md:text-5xl text-red-900 text-center tracking-wider leading-tight'>
            {doc.page_title}
          </h1>
        </StyledDiv>
      </PageHeaderImageBgNew>

      <div className='bg-white pt-8 pb-14'>
        <div className='flex flex-col items-center justify-center mb-14 border-b-4 border-black'>
          <PageVideosNav />
        </div>

        <div className='w-11/12 xl:max-w-screen-xl flex flex-col items-center font-body justify-center mx-auto mb-8'>
          <h2 className='font-display text-3xl md:text-4xl text-gray-900 text-center tracking-wider leading-tight'><span className='text-red-900'>Featured</span> Videos</h2>
        </div>

        <div className='w-11/12 xl:w-10/12 xl:max-w-screen-lg grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mx-auto'>
          {videos.reverse().map(item => {
            return (
              <CATVideo
                videoID={item.video_id}
                videoTitle={item.video_title}
              />
            )
            })
          }
        </div>
      </div>
    </>
  )
}

export default VideosSectionNew