import React from "react"
import { useStaticQuery, graphql } from 'gatsby'

import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionNew from "../../components/sections/VideosSectionNew";

const VideosPage = () => {
  const data = useStaticQuery(graphql`
    query VideosPageQuery {
      prismicVideosPage {
        data {
          meta_title
          meta_description
        }
      }
    }
  `)

  const doc = data.prismicVideosPage.data

  return (
    <MainContainer>
      <HeadDataNew 
        title={doc.meta_title}
        description={doc.meta_description}
      />

      <VideosSectionNew />
    </MainContainer>
  )
}

export default VideosPage